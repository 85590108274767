







































































































































































































































































































/deep/ .el-dialog .el-table th {
  background: rgb(92, 107, 232);
  color: rgb(255, 255, 255);
  border-color: rgb(92, 107, 232);
}
